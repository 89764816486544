// GiveawayLanding.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthModal from '../InfoComponents/AuthModal';
import Footer from '../Footer';
import NavBar from '../NavBar'; // If you prefer NavBar over Header

const GiveawayLanding = () => {
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const [totalEntries, setTotalEntries] = useState(691);
  const [highlight, setHighlight] = useState(false);
  const goalEntries = 1000;
  const navigate = useNavigate();

  const handleParticipateClick = () => {
    setIsAuthModalOpen(true);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setTotalEntries((prevEntries) => {
        if (prevEntries < goalEntries) {
          setHighlight(true);
          setTimeout(() => setHighlight(false), 1000); // Highlight for 1 second
          return prevEntries + 1;
        }
        return prevEntries;
      });
    }, Math.floor(Math.random() * 8000) + 2000); // Random interval between 2-10 seconds

    return () => clearInterval(interval);
  }, []);

  const progressPercentage = (totalEntries / goalEntries) * 100;

  return (
    <div className="flex flex-col justify-between min-h-screen text-black">
      <NavBar />
      {/* Main Content */}
      <div className="flex items-center justify-center flex-1 p-6">
        <div className="flex flex-col items-center justify-center w-full p-8 space-y-8 text-black max-w-7xl lg:flex-row lg:space-y-0 lg:space-x-12">
          {/* Text Section */}
          <div className="flex flex-col items-center w-full space-y-4 text-center lg:w-1/3 lg:items-start lg:text-left lg:order-2 lg:pl-12">
            <h1 className="text-4xl font-semibold text-center">
              We're giving away a 10 oz. Silver Bar!!!
            </h1>
            
            {/* Text below the button */}
            <p className="text-lg text-center">
              Enter for FREE today before it's full!
            </p>
            {/* Button */}
            <button
              className="self-center px-6 py-2 mt-6 font-bold text-center text-white bg-black border-2 border-black rounded-md hover:bg-white hover:text-black"
              onClick={handleParticipateClick}
            >
              Enter Giveaway Now
            </button>
            {/* Progress Bar Section */}
            <div className="w-full mt-4">
              <div className={`mb-4 text-lg font-medium text-center text-gray-700 transition-transform duration-500 ${highlight ? 'scale-110 text-blue-600' : ''}`}>
                Total Entries: {totalEntries} / {goalEntries}
              </div>
              <div className="w-full h-4 mb-6 bg-gray-200 rounded-full">
                <div
                  className="h-4 transition-all duration-500 bg-blue-600 rounded-full"
                  style={{ width: `${progressPercentage}%` }}
                ></div>
              </div>
            </div>
          </div>

          {/* YouTube Video Section */}
          <div className="w-full lg:w-3/4 lg:order-1">
            <iframe
              width="100%"
              height="450"
              src="https://www.youtube.com/embed/7YaTaHdAkGc?autoplay=1&mute=1"
              title="Giveaway Video"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="w-full rounded-md shadow-lg"
            ></iframe>
          </div>
        </div>
      </div>

      {/* AuthModal Component */}
      <AuthModal
        isOpen={isAuthModalOpen}
        onClose={() => setIsAuthModalOpen(false)}
        onAuthSuccess={() => {
          setIsAuthModalOpen(false);
          navigate('/giveaway/confirmation');
        }}
        redirectTo="/giveaway/confirmation"
      />

      <Footer />
    </div>
  );
};

export default GiveawayLanding;
